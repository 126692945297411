<template>
  <teleport to="body">
    <TransitionRoot
      :show="open"
      as="template"
    >
      <Dialog
        as="div"
        class="relative z-[1000]"
        @close="close"
      >
        <div class="fixed inset-0" />
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
              <TransitionChild
                as="template"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from="translate-x-full"
                enter-to="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from="translate-x-0"
                leave-to="translate-x-full"
              >
                <DialogPanel class="pointer-events-auto w-screen sm:max-w-md">
                  <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div class="flex min-h-0 flex-1 flex-col overflow-y-scroll pb-6">
                      <UiModalHeader
                        v-if="titleContext"
                        class="sm:hidden"
                        :title="title"
                        :title-context="titleContext"
                      />
                      <div
                        class="container py-6"
                        :class="{'max-sm:hidden': $slots.header}"
                      >
                        <div class="flex items-start justify-between">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            {{ title }}
                          </DialogTitle>
                          <button
                            class="-m-6 rounded-md bg-white p-4 text-indigo-900 focus:outline-none"
                            type="button"
                            @click="close"
                          >
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon
                              aria-hidden="true"
                              class="h-6 w-6 text-indigo-900"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="container relative mt-6 flex-1">
                        <h2
                          v-if="titleContext"
                          class="mb-6 text-4xl font-bold"
                        >
                          {{ titleContext }}
                        </h2>
                        <slot />
                      </div>
                    </div>
                    <div
                      v-if="actions"
                      class="flex shrink-0 justify-end bg-gray-50 p-4"
                    >
                      <UiButton
                        type="soft"
                        @click="emit('submit')"
                      >
                        <UiIcon
                          size="small"
                          name="Save"
                        />
                        Save
                      </UiButton>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </teleport>
</template>

<script lang="ts" setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';

withDefaults(defineProps<{
  open: boolean
  title: string
  titleContext?: string
  actions?: boolean
}>(), {
  titleContext: undefined,
  actions: true,
});

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update:open', value: boolean): void
  (e: 'submit'): void
}>();

function close() {
  emit('update:open', false);
  emit('close');
}

defineExpose({
  close,
});
</script>
